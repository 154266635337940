import React, {Component, Fragment} from "react";
import {
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
} from "reactstrap";
import Cookies from "universal-cookie";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Redirect} from "react-router-dom";
import parseHtml from "html-react-parser";
import path from "path";
import PropTypes from "prop-types";
import _ from "lodash";

import "../scss/Book.scss";
import {
	humanizeSeconds,
	formatNumber,
	amazonLink,
	bookUrl,
	addPageHead,
	calculatePersonalTime,
} from "../config";
import {
	getBook,
	similarBooks,
	getReadingSpeedTest,
	getReadingSpeed,
	storeCustomWpm,
} from "../actions";
import {bookType, bookDetailsType, readingTestType} from "../types";
import AddToShelfDropdown from "../components/AddToShelfDropdown";
import AmazonButton from "../components/AmazonButton";
import AudibleButton from "../components/AudibleButton";
import BookCarousel from "../components/BookCarousel";
import BookImage from "../components/BookImage";
import Button from "../components/lib/Button";
import ReadingTest from "../components/ReadingTest";
import Spinner from "../components/lib/Spinner";
import Books from "../components/img/Books";
import BooksMirrored from "../components/img/BooksMirrored";
import ReportBookDataModal from "../components/ReportBookDataModal";
import Input from "../components/lib/Input";
import OutboundLink from "../components/OutboundLink";

const cookies = new Cookies();

class Book extends Component {
	state = {
		showTest: false,
		isbnRedirect: false,
		customWPM: undefined,
	};

	constructor(props) {
		super(props);
		this.executeScroll = this.executeScroll.bind(this);
		this.updatePage = this.updatePage.bind(this);
		this.toggleReadingTest = this.toggleReadingTest.bind(this);
		this.goToTest = this.goToTest.bind(this);
		this.myRef = React.createRef();
		this.resetAndToggle = this.resetAndToggle.bind(this);
		this.toggleCustomWpmModal = this.toggleCustomWpmModal.bind(this);
	}

	toggleCustomWpmModal() {
		this.setState({wpmModal: !this.state.wpmModal});
	}

	isLoaded() {
		const id = parseInt(this.props.match.params.id);
		const {isbn} = this.props.match.params;
		const {details} = this.props;
		return (
			details !== null
			&& ((Object.prototype.hasOwnProperty.call(details, "id")
				&& id === details.id)
				|| (Object.prototype.hasOwnProperty.call(details, "isbn")
					&& isbn === details.isbn))
		);
	}

	updatePage() {
		if (!this.isLoaded()) {
			const {isbn} = this.props.match.params;
			if (isbn) {
				this.props.getBook(undefined, isbn);
			} else {
				const id = parseInt(this.props.match.params.id);
				this.props.getBook(id);
			}
		}
	}

	componentDidMount() {
		const {initialize} = this.props;
		this.updatePage();
		this.props.getReadingSpeed();
		const word = this.props.loggedIn
			? localStorage.getItem("personalizedReadingSpeed")
			: cookies.get("personalizedReadingSpeed");
		initialize({wpm: word});
		this.setState({
			customWPM: word,
		});
	}

	componentDidUpdate(prevProps) {
		if (this.state.isbnRedirect && !this.props.match.params.isbn) {
			// page was visited or changed

			this.setState({isbnRedirect: false});
			return;
		}
		// after book changes, close reading test
		const {isbn: paramIsbn} = this.props.match.params || {};
		const {isbn: currentIsbn} = this.props.details || {};
		const {isbn: prevIsbn} = prevProps || {};
		if (paramIsbn && currentIsbn !== prevIsbn) {
			this.setState({isbnRedirect: true});
			return;
		}
		if (prevProps.details === null && this.props.details) {
			// page was visited or changed
		}
		if (
			prevProps.details !== null
			&& prevProps.details.id !== this.props.details.id
		) {
			// page was visited or changed

			this.setState({showTest: false});
			return;
		}
		if (prevProps.match?.params?.id !== this.props.match?.params?.id) {
			this.updatePage();
			this.props.getReadingSpeed();
		}
	}

	toggleReadingTest() {
		this.setState(prevState => {
			let newState = {...prevState, showTest: !prevState.showTest};
			if (newState.showTest) {
				this.props.getReadingSpeedTest();
			}
			return newState;
		});
	}

	renderBookText() {
		const {author, averageReadingTime, title} = this.props.details;
		const readingSpeed = this.props.readingSpeed;
		if (averageReadingTime <= 0) {
			return (
				<p>
					We weren&apos;t able to calculate a reading time for this book. &nbsp;
					<FontAwesomeIcon icon={["fal", "sad-tear"]} size="2x" />
				</p>
			);
		}
		const avgReaderP = (
			<p>
				The average reader, reading at a speed of 300 WPM, would take{" "}
				<b>{humanizeSeconds(averageReadingTime)}</b> to read {title} by {author}
				.{" "}
			</p>
		);

		if (readingSpeed === null || readingSpeed === 0) {
			return (
				<>
					{avgReaderP}
					<p>
						We assumed a speed of 300 WPM for this calculation.{" "}
						<u onClick={this.goToTest} className="alert-link link underline">
							Complete a reading speed test below to calculate using your own
							reading speed
						</u>
					</p>
				</>
			);
		}
		return (
			<>
				<p>
					According to your saved reading speed of {Math.round(readingSpeed)}{" "}
					WPM, it would take you{" "}
					<b>
						{humanizeSeconds(calculatePersonalTime(readingSpeed, averageReadingTime))}
					</b>{" "}
					to read {title} by {author}.
				</p>
				{avgReaderP}
			</>
		);
	}

	renderInfoCols() {
		const {wordCount = 0, verified = false, numPages = 0} = this.props.details;
		const audioBookDuration = _.get(
			this.props.details,
			"audioBook.duration",
			null,
		);
		return (
			<Row>
				<Col xs={4}>
					<h3>
						Word Count{" "}
						{verified ? (
							<small className="text-success">(verified)</small>
						) : (
							<small className="text-muted">(estimated)</small>
						)}
					</h3>
					<p>{wordCount > 0 ? formatNumber(wordCount) : "Not available"}</p>
				</Col>
				<Col xs={4}>
					<h3>Number of pages</h3>
					<p>{numPages > 0 ? formatNumber(numPages) : "Not available"}</p>
				</Col>
				<Col xs={4}>
					<h3>Audiobook Duration</h3>
					<p>
						{audioBookDuration > 0
							? humanizeSeconds(audioBookDuration * 60)
							: "Not available"}
					</p>
				</Col>
			</Row>
		);
	}

	renderDescription(description) {
		return description === null ? null : (
			<Row>
				<Col className="d-none d-sm-block">
					<h2>Description</h2>
					<p>{parseHtml(description)}</p>
				</Col>
			</Row>
		);
	}

	renderGoodReadsCredit() {
		const {goodreadsId = 0} = this.props.details;
		const goodreadsLink =			goodreadsId > 0
			? `https://goodreads.com/book/show/${goodreadsId}`
			: "https://goodreads.com/";
		return (
			<Row>
				<Col>
					<p className="text-muted data-text">
						Data and resources retrieved from:
					</p>
					<OutboundLink
						eventLabel="goodreads"
						to={goodreadsLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							className="goodreads-logo"
							src={path.join(process.env.PUBLIC_URL, "/img/Goodreads-Logo.svg")}
							alt="GoodReads Logo"
						/>
					</OutboundLink>
				</Col>
			</Row>
		);
	}

	executeScroll = () => {
		if (this.myRef.current) {
			this.myRef.current.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "start",
			});
		}
	};

	// toggles reading test, and if the test was closed prior to clicking the
	// prompt it scrolls to it, and if it was open before clicking the prompt it won't scroll
	resetAndToggle = () => {
		const {showTest} = this.state;
		this.toggleReadingTest();

		if (!showTest) {
			this.executeScroll();
		}
	};

	// always opens and scrolls to test
	goToTest = () => {
		const {showTest} = this.state;
		if (!showTest) {
			this.toggleReadingTest();
		}
		this.executeScroll();
	};

	renderBookDetails() {
		if (!this.isLoaded()) {
			return <Spinner />;
		}
		const {handleSubmit, email, loggedIn, readingTest, details} = this.props;
		const {showTest} = this.state;
		const {
			asin = null,
			author,
			id,
			image,
			// isbn,
			title,
			// samplePassage = null,
			description = null,
		} = this.props.details;

		const openTestText = (
			<Fragment>
				<FontAwesomeIcon icon={["far", "stopwatch"]} />
				Test Reading Speed
			</Fragment>
		);
		const closeTestText = (
			<Fragment>
				<FontAwesomeIcon icon={["fal", "times"]} size="lg" />
				Close reading speed test
			</Fragment>
		);

		return (
			<Container
				fluid={true}
				style={{display: "flex", flexDirection: "column"}}
			>
				{addPageHead(`How Long to Read ${title}`, null, true)}
				<Row className="book-main align-items-stretch">
					<Col xs={{size: 12, order: 2}} sm={{size: 6, order: 1}} md={7}>
						<h1>{title}</h1>
						<p className="author-text">By {author}</p>
						{this.renderBookText()}

						<AmazonButton
							asin={asin}
							title={title}
							style={{marginBottom: 24}}
						/>
						<AudibleButton title={title} style={{marginBottom: 24}} />

						<Row>
							<Col sm={{size: 6, order: 2}} style={{marginBottom: 24}}>
								<AddToShelfDropdown />
							</Col>
							<Col sm={6} style={{marginBottom: 24}}>
								<Button color="primary" outline onClick={this.resetAndToggle}>
									{!showTest ? openTestText : closeTestText}
								</Button>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
									<Row>
										<Col>
											<Row>
												<Col xs={8} md={9}>
													<Field
														id="wpm"
														name="wpm"
														type="number"
														component={Input}
														placeholder="Enter speed in words per minute"
													/>
												</Col>
												<Col xs={4} md={3}>
													<Button id="submit" name="submit" color="primary">
														Save
													</Button>
												</Col>
											</Row>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					</Col>

					<Col
						xs={{order: 1}}
						sm={{size: 5, offset: 1, order: 2}}
						md={4}
						className="cover-container"
					>
						<ReportBookDataModal email={email} id={id} loggedIn={loggedIn} />
						<BookImage
							id={id}
							image={image}
							link={asin === null ? null : amazonLink(asin)}
							openInNew={true}
							title={title}
							author={author}
							size="lg"
						/>
					</Col>
				</Row>
				{showTest && readingTest !== null ? (
					<ReadingTest {...readingTest} book={details} />
				) : null}
				{this.renderInfoCols()}

				{this.renderDescription(description)}

				<div ref={this.myRef}>{this.renderGoodReadsCredit()}</div>
			</Container>
		);
	}

	renderSimilarBooks() {
		if (!this.isLoaded()) {
			return <Spinner />;
		}
		return (
			<BookCarousel
				name="Similar Books"
				books={this.props.similarBooks}
				showSpinner={false}
			/>
		);
	}

	onSubmit = formValues => {
		if (_.isEmpty(formValues?.wpm) || _.isNil(formValues?.wpm)) {
			this.setState({
				customWPM: formValues?.wpm,
			});
			localStorage.removeItem("personalizedReadingSpeed");
			cookies.remove("personalizedReadingSpeed");
			return this.props.storeCustomWpm({word: formValues?.wpm});
		} else if (!_.isNil(formValues?.wpm)) {
			this.setState({
				customWPM: formValues?.wpm,
			});
			this.toggleCustomWpmModal();
			return this.props.storeCustomWpm({word: formValues?.wpm});
		}
	};

	renderWPMResultModal() {
		const {details, modalClassName} = this.props;
		const {customWPM, wpmModal} = this.state;
		const averageReadingTime = details?.averageReadingTime;
		return (
			<Modal
				isOpen={wpmModal}
				toggle={this.toggleCustomWpmModal}
				className={modalClassName}
				centered={true}
			>
				<ModalHeader toggle={this.toggleCustomWpmModal}>
					Custom Reading Speed Result
				</ModalHeader>
				<ModalBody>
					{customWPM === undefined
						? null
						: parseHtml(`According to your saved reading speed of <b>${customWPM} WPM</b>, it would take you <b>${humanizeSeconds(calculatePersonalTime(customWPM, averageReadingTime))}</b> to read ${details?.title}.`)}
				</ModalBody>
				{details === null
				|| !("asin" in details && details?.asin !== null) ? null : (
						<ModalFooter>
							<AmazonButton asin={details?.asin} />
						</ModalFooter>
					)}
			</Modal>
		);
	}

	render() {
		if (this.state.isbnRedirect && this.props.match.params.isbn) {
			const {id, title} = this.props.details;
			return <Redirect to={bookUrl(id, title)} />;
		}
		if (this.isLoaded()) {
			const {id, title} = this.props.details;
			const currentPath = window.location.pathname;
			const formattedPath = bookUrl(id, title);
			if (currentPath !== formattedPath) {
				return <Redirect to={formattedPath} />;
			}
		}
		return (
			<div className="main">
				{addPageHead()}

				<div className="book-details-content-wrapper">
					<div className="background">
						<div id="books" className="books d-none d-lg-block">
							<Books id="books-svg" />
						</div>

						<div
							id="books-mirrored"
							className="books-mirrored d-none d-lg-block"
						>
							<BooksMirrored id="books-mirrored-svg" />
						</div>
					</div>

					<div className="book-details-content">
						<section className="book-details">
							{this.renderBookDetails()}
						</section>

						<hr className="similar-books-divider" />

						<section className="similar-books">
							{this.renderSimilarBooks()}
						</section>
					</div>
				</div>
				{this.renderWPMResultModal()}
			</div>
		);
	}
}

Book.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string,
			isbn: PropTypes.string,
		}).isRequired,
	}).isRequired,
	details: bookDetailsType,
	similarBooks: PropTypes.arrayOf(PropTypes.shape(bookType)),
	readingTest: PropTypes.shape(readingTestType),
	readingSpeed: PropTypes.string,
	email: PropTypes.string,
	loggedIn: PropTypes.bool,
	customWpm: PropTypes.string,
	getBook: PropTypes.func.isRequired,
	getSimilarBooks: PropTypes.func.isRequired,
	getReadingSpeedTest: PropTypes.func.isRequired,
	getReadingSpeed: PropTypes.func.isRequired,
	storeCustomWpm: PropTypes.func.isRequired,
	initialize: PropTypes.func.isRequired,
	modalClassName: PropTypes.string,
	handleSubmit: PropTypes.func,
};

function mapStateToProps({
	book: {details, similar},
	readingTest,
	account: {readingSpeed, email, loggedIn, customWpm},
}) {
	return {
		details,
		similarBooks: similar,
		readingTest: readingTest.currentTest,
		readingSpeed,
		email,
		loggedIn,
		customWpm,
	};
}

const validate = formValues => {
	const {wpm} = formValues;
	let errors = {};
	if (Number(wpm) === 0 && !_.isEmpty(wpm)) {
		errors.wpm = "Please enter more than zero";
	}
	return errors;
};

const formWrapped = reduxForm({
	form: "wpmForm",
	validate,
})(Book);

export default connect(mapStateToProps, {
	getBook,
	getSimilarBooks: similarBooks,
	getReadingSpeedTest,
	getReadingSpeed,
	storeCustomWpm,
})(formWrapped);
