import React, {Component, Fragment} from "react";
import {Field, reduxForm, reset} from "redux-form";
import {
	Button,
	Col,
	Form,
	FormFeedback,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
} from "reactstrap";
import PropTypes from "prop-types";
import v from "validator";

import {formType} from "../types";
import {createReportTest} from "../actions";
import Input from "./lib/Input";
import {connect} from "react-redux";

class ReportBookDataModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpenReportmodal: true,
		};
		this.toggleReportModal = this.toggleReportModal.bind(this);
	}

	toggleReportModal() {
		this.setState({isOpenReportmodal: !this.state.isOpenReportmodal});
	}

	responseMessage(submitSucceeded, error) {
		if (!submitSucceeded) {
			return <FormFeedback style={{display: "block"}}>{error}</FormFeedback>;
		}
	}

	handleReportModal() {
		const {
			submitting,
			submitSucceeded,
			submitFailed,
			error,
			handleSubmit,
			email,
			id,
			loggedIn,
		} = this.props;

		const onSubmit = formValues => {
			const body = {
				bookId: id,
				email: !loggedIn ? formValues.email : email,
				description: formValues.description,
			};
			this.setState({isOpenReportmodal: !this.state.isOpenReportmodal});
			return this.props.createReportTest(body);
		};

		return (
			<Modal
				isOpen={!this.state.isOpenReportmodal}
				toggle={this.toggleReportModal}
				className={this.props.modalClassName}
				centered={true}
			>
				<ModalHeader toggle={this.toggleReportModal}>
					Report Inaccurate Book Data
				</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<Field
							id="description"
							name="description"
							type="textarea"
							component={Input}
							placeholder="Describe what appears to be inaccurate (reading time, word count, audiobook length)"
							label="Description"
						/>
						{!loggedIn && (
							<Field
								id="email"
								name="email"
								type="email"
								component={Input}
								placeholder="Email"
								label="Email (optional)"
							/>
						)}
						<Row className="login-btns row justify-content-center m-2">
							<Col md={6}>
								<Button
									id="submit"
									name="submit"
									disabled={submitting || submitSucceeded}
									color="primary"
								>
									Submit
								</Button>
							</Col>
						</Row>
					</Form>
					{!(submitSucceeded || submitFailed)
						? null
						: this.responseMessage(submitSucceeded, error)}
				</ModalBody>
			</Modal>
		);
	}

	render() {
		return (
			<Fragment>
				<Row className="test-row justify-content-end">
					<p
						className="alert-link link underline"
						onClick={this.toggleReportModal.bind(this)}
					>
						<u>Report inaccurate data</u>
					</p>
				</Row>
				{this.handleReportModal()}
			</Fragment>
		);
	}
}

ReportBookDataModal.propTypes = {
	...formType,
	modalClassName: PropTypes.string,
	createReportTest: PropTypes.func.isRequired,
	email: PropTypes.string,
	id: PropTypes.number,
	loggedIn: PropTypes.bool,
};

const validate = formValues => {
	const {description = "", email = ""} = formValues;
	let errors = {};
	if (description === "") {
		errors.description = "Please enter description";
	}
	if (email && !v.isEmail(email)) {
		errors.email = "Please enter a valid email";
	}
	return errors;
};

const afterSubmitSuccess = (result, dispatch) => dispatch(reset("reportForm"));

const formWrapped = reduxForm({
	form: "reportForm",
	validate,
	onSubmitSuccess: afterSubmitSuccess,
})(ReportBookDataModal);

export default connect(null, {createReportTest})(formWrapped);
