import React from "react";
import {Router, Route, Redirect, Switch} from "react-router-dom";

import "./scss/App.scss";
import history from "./history";
import About from "./pages/About";
import Book from "./pages/Book";
import Bookshelf from "./pages/account/Bookshelf";
import Contact from "./pages/Contact";
import CreateBookshelf from "./pages/account/CreateBookshelf";
import Disclaimer from "./pages/Disclaimer";
import EmailLogin from "./pages/EmailLogin";
import ErrorPage from "./pages/Error";
import Footer from "./components/Footer";
import GoodreadsLogin from "./pages/GoodreadsLogin";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import MyBooks from "./pages/account/MyBooks";
import Navbar from "./components/Navbar";
import ReadingTestPage from "./pages/account/ReadingTestPage";
import ReadingTests from "./pages/account/ReadingTests";
import ResetPasswordRequest from "./pages/ResetPasswordRequest";
import SearchResults from "./pages/SearchResults";
import SetNewPassword from "./pages/SetNewPassword";
import Settings from "./pages/account/Settings";
import Signup from "./pages/Signup";
import Suggest from "./pages/Suggest";
import ManageBooks from "./pages/ManageBooks";
import WithRole from "./hoc/withRole";

import {library} from "@fortawesome/fontawesome-svg-core";
import {
	faBookOpen,
	faBooks,
	faCheck,
	faSadTear,
	faTimes as falFaTimes,
} from "@fortawesome/pro-light-svg-icons";
import {
	faChevronDown,
	faChevronUp,
	faSearch,
	faStopwatch,
	faTimes,
	faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import {faStop, faPlay, faBooksMedical} from "@fortawesome/pro-solid-svg-icons";
import {
	faAmazon,
	faAudible,
	faFacebook,
	faFacebookF,
	faGoodreads,
	faInstagram,
	faReddit,
	faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import ScrollToTop from "./components/ScrollToTop";
import AnalyticsWrapper from "./hoc/withAnalytics";

library.add(
	faAmazon,
	faAudible,
	faBookOpen,
	faBooks,
	faBooksMedical,
	faCheck,
	faChevronDown,
	faFacebook,
	faFacebookF,
	faGoodreads,
	faInstagram,
	falFaTimes,
	faPlay,
	faPlus,
	faReddit,
	faSadTear,
	faSearch,
	faStop,
	faStopwatch,
	faTimes,
	faTwitter,
	faChevronUp,
);

const App = () => {
	return (
		<div className="App">
			<Router history={history}>
				<AnalyticsWrapper />
				<ScrollToTop>
					<Route path="/" component={Navbar} />
					<Switch>
						<Route exact path="/" component={Home} />
						<Route exact path="/results/:qs" component={SearchResults} />
						<Route exact path="/results" render={() => <Redirect to="/" />} />
						<Route exact path="/books/isbn/:isbn" component={Book} />
						<Route exact path="/books/:id/:title" component={Book} />
						<Route exact path="/books/:id" component={Book} />
						<Route exact path="/about" component={About} />
						<Route exact path="/disclaimer" component={Disclaimer} />
						<Route exact path="/contact" component={Contact} />
						<Route exact path="/recommended" component={Suggest} />
						<WithRole
							exact
							path="/admin/manage-books/:type"
							component={ManageBooks}
						/>
						<Route exact path="/recommended/:min-:max" component={Suggest} />
						<Route exact path="/login" component={Login} />
						<Route
							exact
							path="/login/goodreads/:token"
							component={GoodreadsLogin}
						/>
						<Route exact path="/login/email/:token" component={EmailLogin} />
						<Route exact path="/signup" component={Signup} />
						<Route exact path="/logout" component={Logout} />
						<Route exact path="/account/books" component={MyBooks} />
						<Route
							exact
							path="/account/books/new"
							component={CreateBookshelf}
						/>
						<Route path="/account/books/:shelfName" component={Bookshelf} />
						<Route
							exact
							path="/account/reading-speed"
							component={ReadingTests}
						/>
						<Route
							exact
							path="/account/reading-speed/:id"
							component={ReadingTestPage}
						/>
						<Route exact path="/account/settings" component={Settings} />
						<Route
							exact
							path="/account/reset-password"
							component={ResetPasswordRequest}
						/>
						<Route
							exact
							path="/account/reset-password/:token"
							component={SetNewPassword}
						/>
						<Route exact path="/error" component={ErrorPage} status={404} />
						<Route render={() => <Redirect to="/error" />} />
					</Switch>
					<Footer />
				</ScrollToTop>
			</Router>
		</div>
	);
};

export default App;
