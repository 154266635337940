import Cookies from "universal-cookie";
import {
	SIGN_UP,
	LOG_IN,
	TOKEN_LOG_IN,
	LOG_OUT,
	GET_SHELVES,
	GET_READING_SPEED,
	UPDATE_PASSWORD,
	GET_ACCOUNT,
	UPDATE_ACCOUNT,
	ADD_BOOK_TO_SHELF,
	DELETE_BOOK_FROM_SHELF,
	CREATE_SHELF,
} from "../actions/types";

const cookies = new Cookies();
const _ = require("lodash");

const INITIAL_STATE = {
	email: null,
	loggedIn: !!cookies.get("hltr_token", {path: "/"}),
	profileImage: null,
	readingSpeed: null,
	receivePromotions: null,
	shelves: null,
	createShelfResponse: null,
	signupResponse: null,
	token: cookies.get("hltr_token", {path: "/"}) || null,
	updatePasswordResponse: null,
	userId: null,
	username: null,
	firstName: null,
	lastName: null,
	goodreadsId: null, // null means not loaded, 0 means loaded and no goodreads account
	emailConfirmed: null,
};

const processAddToShelf = (shelves, payload) => {
	if (!payload.exclusiveFlag) {
		return shelves.map(s => (s.name === payload.name ? payload : s));
	}
	let exclusiveBooks = new Set();
	payload.books.forEach(b => exclusiveBooks.add(b.id));
	return shelves.map(s => {
		if (s.name === payload.name) return payload;
		if (!s.exclusiveFlag) return s;
		return {
			...s,
			books: s.books.filter(b => !exclusiveBooks.has(b.id)),
		};
	});
};

const processDeleteFromShelf = (shelves, payload) => {
	const {name, bookId, response} = payload;
	if (response !== "success") return shelves;
	return shelves.map(s => {
		if (s.name !== name) return s;
		return {
			...s,
			books: s.books.filter(b => b.id !== bookId),
		};
	});
};

export default (state = INITIAL_STATE, action) => {
	const {type, payload} = action;
	switch (type) {
	case SIGN_UP:
		cookies.set("hltr_token", payload.token, {
			path: "/",
			maxAge: 60 * 60 * 24 * 365,
		});
		return {
			...state,
			signupResponse: payload.response,
			loggedIn: true,
			token: payload.token,
			profileImage: payload.profileImage,
		};
	case LOG_IN:
		cookies.set("hltr_token", payload.token, {
			path: "/",
			maxAge: 60 * 60 * 24 * 365,
		});
		return {
			...state,
			token: payload.token,
			profileImage: payload.profileImage,
			loggedIn: true,
		};
	case TOKEN_LOG_IN:
		cookies.set("hltr_token", payload, {
			path: "/",
			maxAge: 60 * 60 * 24 * 365,
		});
		return {...state, token: payload, loggedIn: true};
	case LOG_OUT:
		cookies.remove("hltr_token", {path: "/"});
		return {
			...INITIAL_STATE,
			email: state.email,
			loggedIn: false,
			token: null,
		};
	case GET_SHELVES:
		return {...state, shelves: payload};
	case GET_READING_SPEED:
		return {...state, readingSpeed: payload.personalizedReadingSpeed};
	case UPDATE_PASSWORD:
		return state;
	case GET_ACCOUNT:
		return {...state, ...payload, loggedIn: true};
	case UPDATE_ACCOUNT:
		return {...state, ...payload};
	case ADD_BOOK_TO_SHELF:
		return {
			...state,
			shelves: processAddToShelf(state.shelves, payload),
		};
	case DELETE_BOOK_FROM_SHELF:
		return {
			...state,
			shelves: processDeleteFromShelf(state.shelves, payload),
		};
	case CREATE_SHELF:
		return {
			...state,
			shelves: _.sortBy([...state.shelves, payload], s => s.name),
			createShelfResponse: payload,
		};
	default:
		return state;
	}
};
