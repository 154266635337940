import React, {Component} from "react";
import {connect} from "react-redux";
import {Container} from "reactstrap";
import PropTypes from "prop-types";

import "../scss/Search.scss";
import {createSuggestion} from "../actions";
import {humanizeSeconds, addPageHead} from "../config";
import {bookType} from "../types";
import SuggestForm from "../components/SuggestForm";
import WomanMap from "../components/img/WomanMap";
import BookCarousel from "../components/BookCarousel";

class Suggest extends Component {
	componentDidMount() {
		const min = Number(this.props.match.params.min);
		const max = Number(this.props.match.params.max);
		if (
			this.props.suggestion === null
			|| (!isNaN(min) && min > 0)
			|| (!isNaN(max) && max > 0)
		) {
			this.props.createSuggestion({
				readingTimeRange: [isNaN(min) ? 0 : min, isNaN(max) ? 0 : max],
			});
		}
	}

	render() {
		const {suggestion} = this.props;

		const min = Number(this.props.match.params.min);
		const max = Number(this.props.match.params.max);
		let suggestFormValues = {};
		if (!isNaN(min) && min > 0) {
			suggestFormValues.minTimeString = humanizeSeconds(min);
		}
		if (!isNaN(max) && max > 0) {
			suggestFormValues.maxTimeString = humanizeSeconds(max);
		}

		return (
			<div className="main">
				{addPageHead("Book Recommendations")}
				<section className="search search-results">
					<WomanMap id="woman-map" />
					<Container className="search-content">
						<h2>Looking for a book to read? We&apos;ll help you find one!</h2>
						<SuggestForm initialValues={suggestFormValues} />
					</Container>
				</section>

				<div className="search-content-wrapper">
					<span id="accent" />

					<Container className="search-inner-wrapper d-flex flex-column justify-content-center align-items-middle">
						<section className="search-result-books">
							{Object.keys(suggestion || {}).map((suggestionKey, index) => {
								const books = suggestion[suggestionKey];
								return books?.length ? (
									<BookCarousel
										key={suggestionKey}
										name={suggestionKey}
										books={books}
									/>
								) : null;
							})}
						</section>
					</Container>
				</div>
			</div>
		);
	}
}

Suggest.propTypes = {
	suggestion: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape(bookType))),
	createSuggestion: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			min: PropTypes.string,
			max: PropTypes.string,
		}).isRequired,
	}).isRequired,
};

function mapStateToProps({suggestion: {suggestion = null}}) {
	return {suggestion};
}

export default connect(mapStateToProps, {createSuggestion})(Suggest);
