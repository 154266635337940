// books
export const AUTOFILL_BOOKS = "AUTOFILL_BOOKS";
export const GET_BOOK = "GET_BOOK";
export const POPULAR_BOOKS = "POPULAR_BOOKS";
export const RECOMMENDED_BOOKS = "RECOMMENDED_BOOKS";
export const SEARCH_BOOKS = "SEARCH_BOOKS";
export const SIMILAR_BOOKS = "SIMILAR_BOOKS";

// contact
export const CONTACT = "CONTACT";

// account
export const SIGN_UP = "SIGN_UP";
export const LOG_IN = "LOG_IN";
export const TOKEN_LOG_IN = "TOKEN_LOG_IN";
export const CREATE_PASSWORD_RESET = "CREATE_PASSWORD_RESET";
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET";
export const LOG_OUT = "LOG_OUT";
export const GET_SHELVES = "GET_SHELVES";
export const GET_READING_SPEED = "GET_READING_SPEED";
export const GET_READING_SPEED_TEST = "GET_READING_SPEED_TEST";
export const GET_READING_SPEED_TESTS = "GET_READING_SPEED_TESTS";
export const CLEAR_READING_SPEED_TEST = "CLEAR_READING_SPEED_TEST";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const GET_ACCOUNT = "GET_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const ADD_BOOK_TO_SHELF = "ADD_BOOK_TO_SHELF";
export const DELETE_BOOK_FROM_SHELF = "DELETE_BOOK_FROM_SHELF";
export const CREATE_SHELF = "CREATE_SHELF";

// suggest a book
export const GET_GENRES = "GET_GENRES";
export const CREATE_SUGGESTION = "CREATE_SUGGESTION";
export const CLEAR_SUGGESTION = "CLEAR_SUGGESTION";

//report test
export const CREATE_REPORT = "CREATE_REPORT";

//all books for dashboard
export const ALL_RECOMMENDED_BOOKS = "ALL_RECOMMENDED_BOOKS";
export const ALL_POPULAR_BOOKS = "ALL_POPULAR_BOOKS";
