import {
	AUTOFILL_BOOKS,
	GET_BOOK,
	POPULAR_BOOKS,
	RECOMMENDED_BOOKS,
	SEARCH_BOOKS,
	SIMILAR_BOOKS,
	ALL_POPULAR_BOOKS,
	ALL_RECOMMENDED_BOOKS,
} from "../actions/types";

const _ = require("lodash");

const INITIAL_STATE = {
	autofillResults: null,
	details: null,
	popular: null,
	recommended: null,
	searchResults: null,
	similar: null,
};

export default (state = INITIAL_STATE, action) => {
	const {type, payload} = action;
	switch (type) {
	case AUTOFILL_BOOKS:
		return {...state, autofillResults: payload};
	case GET_BOOK:
		return {
			...state,
			details: _.omit(payload, ["similarBooks"]),
			similar: payload.similarBooks,
		};
	case POPULAR_BOOKS:
		return {...state, popular: payload};
	case ALL_POPULAR_BOOKS:
		return {...state, allPopularBooks: payload};
	case RECOMMENDED_BOOKS:
		return {...state, recommended: payload};
	case ALL_RECOMMENDED_BOOKS:
		return {...state, allRecommendedBooks: payload};
	case SEARCH_BOOKS:
		return {...state, searchResults: payload};
	case SIMILAR_BOOKS:
		return {...state, similar: payload};
	default:
		return state;
	}
};
