import React, {Component} from "react";
import {connect} from "react-redux";
import {Container} from "reactstrap";
import PropTypes from "prop-types";

import "../../scss/Account.scss";
import {addPageHead} from "../../config";
import {SETTINGS} from "./types";
import AccountPasswordForm from "../../components/AccountPasswordForm";
import AccountSettingsForm from "../../components/AccountSettingsForm";
import AccountWrapper from "../../components/AccountWrapper";
import ThirdPartyAuthForm from "../../components/ThirdPartyAuthForm";
import OutboundLink from "../../components/OutboundLink";

class Settings extends Component {
	renderGoodreadsForm() {
		const {goodreadsId} = this.props;
		if (goodreadsId === null) {
			return null;
		}
		if (goodreadsId === 0) {
			return <ThirdPartyAuthForm />;
		}
		return (
			<p>
				Connected to:&nbsp;
				<OutboundLink
					to={`https://www.goodreads.com/user/show/${goodreadsId}`}
					target="_blank"
					rel="noopener noreferrer"
					eventLabel="account-goodreads"
					className="secondary"
				>
					GoodReads account {goodreadsId}
				</OutboundLink>
			</p>
		);
	}
	render() {
		return (
			<AccountWrapper page={SETTINGS}>
				{addPageHead("Settings")}
				<h1 className="content-title desktop">Settings</h1>
				<Container className="wrapper">
					<h2 className="form-title form-title-first">Edit Account Details</h2>
					<AccountSettingsForm />

					<h2 className="form-title">Change Password</h2>
					<AccountPasswordForm />

					<h2 className="form-title">GoodReads Account</h2>
					{this.renderGoodreadsForm()}
				</Container>
			</AccountWrapper>
		);
	}
}

Settings.propTypes = {
	goodreadsId: PropTypes.number,
};

function mapStateToProps({account: {goodreadsId}}) {
	return {goodreadsId};
}

export default connect(mapStateToProps, null)(Settings);
