import React, {Component} from "react";
import {addPageHead} from "../config";
import ManLounging from "../components/img/ManLounging";
import "../scss/Error.scss";

class Error extends Component {
	render() {
		const params = new URLSearchParams(window.location.search.slice(1));
		const status = params.get("status") || 404;
		const message = params.get("_error");

		return (
			<>
				<div className="main formpage contact align-items-stretch">
					{addPageHead("Error")}
					<div className="main error">
						<div className="error-code">
							<h1>Woah... that wasn&apos;t supposed to happen.</h1>
							<h5 className="error-code-message">
								{`Error ${status}: ${message
									|| "We couldn't find the page you were looking for. "}`}{" "}
								&#128533;{" "}
							</h5>
						</div>
					</div>
					<span id="bottom-slant" />
					<div className="error-image d-flex align-items-center justify-content-center">
						<ManLounging />
					</div>
				</div>
			</>
		);
	}
}

export default Error;
